import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Versions } from '../versions'

const Ver = () => {
    return (
        <L10nProvider>
            <Versions />
        </L10nProvider>
    )
}

export default Ver;
